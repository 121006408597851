import { SLOT_PUBLIC_LABEL } from 'config/app'
import yup from 'utils/yup'

export default yup.object({
  slot: yup.object().label(SLOT_PUBLIC_LABEL).nullable().required(),
  fee: yup
    .object()
    .label('Fee')
    .nullable()
    .when('slot', (slot, schema) => (slot?.fees.length > 0 ? schema.required() : schema)),
})
